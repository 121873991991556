<script lang="ts">
import BaseBack from '/~/components/base/back/base-back.vue'
import PostBox from '/~rec/components/post/box/post-box.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import RecBaseState from '/~rec/components/state/base-state.vue'
import transformPostClass from '/~rec/utils/transform-post-class'
import api from '/~rec/core/api'
import { ApiResponseData } from '/~/types/api'
import { PostBoxData } from '/~/types/extensions'
import { RecroomPost } from '/~/extensions/rec-room/core'
import { RecroomPostPoll } from '/~/extensions/rec-room/core/post-poll'

export default {
  name: 'rec-single-post',
  components: {
    BaseLoader,
    BaseBack,
    PostBox,
    BaseButton,
    RecBaseState,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      fetching: false,
      post: null as RecroomPost | RecroomPostPoll | null,
    }
  },
  computed: {
    showCommentsOnInit() {
      return Boolean(this.$route?.query?.comments)
    },
    backRoute() {
      let route = { name: 'rec-feed' }
      const toEvent = this.post?.toEvent
      const toGroup = this.post?.toGroup

      if (toEvent) {
        route = {
          name: 'rec-event',
          params: { eventId: toEvent?.id },
        }
      } else if (toGroup?.name !== 'All members') {
        route = {
          name: 'rec-group',
          params: { groupId: toGroup?.id },
        }
      }

      return route
    },
  },
  watch: {
    id() {
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.fetching = true
      try {
        this.post = await this.getPost()
      } catch (error) {
        console.error('rec-room', error)
      } finally {
        this.fetching = false
      }
    },
    async getPost() {
      const { data } = await api.get<ApiResponseData<PostBoxData>>(
        `/posts/${this.id}`
      )

      return transformPostClass(data)
    },
  },
}
</script>

<template>
  <div
    class="relative mx-auto flex h-full w-full max-w-screen-xs grow flex-col p-5 md:p-[30px]"
  >
    <base-loader v-if="fetching" class="absolute inset-0" />

    <template v-else>
      <div v-if="post">
        <base-back class="mr-auto" :to="backRoute" />

        <post-box
          class="mx-auto mt-5 max-w-xl bg-white sm:mt-[30px]"
          :post="post"
          :show-comments="showCommentsOnInit"
        />
      </div>

      <div v-else class="absolute inset-0 flex flex-col justify-center p-2.5">
        <rec-base-state
          title="Something went wrong..."
          subtitle="It seems to be a problem with this page, please return to home."
          image="recroom/state/404-empty-state.svg"
        />

        <div class="mx-auto mt-4 w-40 md:w-64">
          <base-button full-width @click="$router.push({ name: 'rec-feed' })">
            <span class="text-sm font-bold sm:text-base">Take me home</span>
          </base-button>
        </div>
      </div>
    </template>
  </div>
</template>
